import React, { useState } from 'react'
import { useEffect } from 'react'
import closeIcon from '../../assets/images/close.png'
import downloadIcon from '../../assets/images/file_download_icon_green.svg'
import rotateLeftIcon from '../../assets/images/r-left.png'
import rotateRightIcon from '../../assets/images/r-right.png'
import '../AssignItemsPopup/assignItemsPopup.scss'
import './mediaSlider.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Swiper as SwiperInterface } from 'swiper'
import 'swiper/css'
import { Navigation, Pagination, Keyboard } from 'swiper'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import Modal from 'react-modal'
import Flex from '../flex/Flex'
import i18n from "i18next";
import {rotateImageCall} from "../../api/apiCalls";
import Loader from "../Loader/loader";

const customStyles = {
  content: {
    //   top: '50%',
    //   left: '50%',
    top: 'auto',
    //   left: 'auto',
    right: 'auto',
    bottom: 'auto',
    left: '50%',
    transform: 'translateX(-50%)',
    display: 'flex',
    flexDirection: 'column',
    //   marginRight: '-50%',
    //   transform: 'translate(-50%, -50%)',
    background: 'none',
    border: 'none',
    position: 'relative',
    width: 'stretch',
    boxSizing: 'border-box',
    height: '100vh',
  },
  overlay: {
    background: 'rgba(174,174,174,0.5)',
    backdropFilter: 'blur(15px)',
    // zIndex: '9999999999'
    WebkitBackdropFilter: 'blur(5px)',
  },
} as const

const MediaSlider = (props: any) => {
  const [swiperInstance, setSwiperInstance] = useState<SwiperInterface>()
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [loading, showLoader] = useState(false)
  useEffect(() => {
    document.body.style.overflow = 'hidden'

    const keyDownHandler = (event: any) => {
      if (event.key === 'ArrowRight') {
        event.preventDefault()
        swiperInstance?.slideNext()
      }
      if (event.key === 'ArrowLeft') {
        event.preventDefault()
        swiperInstance?.slidePrev()
      }
    }

    document.addEventListener('keydown', keyDownHandler)
    return () => {
      document.body.style.overflow = 'auto'
      document.removeEventListener('keydown', keyDownHandler)
    }
  }, [])

  const handleSlideChange = (e: any) => {
    if (props.isVideo) {
      const vidArr: any = document.getElementsByClassName('media-slider-video')
      for (const vid of vidArr) {
        vid.pause()
      }
    }
  }

  const reloadImage = (url: string) => {
    // To force reload, append a unique query parameter
    setImageUrl(`${url}?t=${new Date().getTime()}`);
  };

  const rotateImage = async (url: string, angle: number) => {
    if(url) {
      showLoader(true)
      await rotateImageCall(props.objectKey, url, angle)
      reloadImage(url)
      showLoader(false)
    }
  }

  const fetchImage = async (url: string): Promise<Blob> => {
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Cache-Control': 'no-store', // Ensure no caching
      'Pragma': 'no-cache',        // Ensure no caching
    }});
  if (!response.ok) {
    throw new Error(`Failed to fetch image: ${response.statusText}`);
  }
  const blob = await response.blob();
  return blob;
};

  const downloadImage = async (url: string) => {
  try {
    const imageBlob = await fetchImage(url);
    if (imageBlob.size === 0) {
      throw new Error("Image blob is empty");
    }
    const imageBase64 = URL.createObjectURL(imageBlob);

    const a = document.createElement('a');
    a.style.setProperty('display', 'none');
    document.body.appendChild(a);
    a.download = url.split('/').pop() || 'download';
    a.href = imageBase64;
    a.click();
    a.remove();
    URL.revokeObjectURL(imageBase64); // Clean up the object URL after download
  } catch (error) {
    console.error("Error downloading the image:", error);
  }
};

  /*
  commented this, as on digital check report, this will not work; it is still required in case of
  item / container details

  const { data: { markings } = {} } = useQuery(['markingsList'], () => getMarkings(), {
    enabled: props.mediaType === 'markings',
  })
  */
  return (
    <div id='temp'>
      <Modal
        isOpen={true}
        // onAfterOpen={afterOpenModal}
        onRequestClose={() => props.dismissHandler()}
        style={customStyles}
        ariaHideApp={false}
      >
        {loading && <Loader />}
        <div className='media-slider-outer-container'>
          <div className='display_flex media-slider-close-icon-container'>
            <img className='cross-icon cursor_pointer media-slider-cross-icon'
                 onClick={() => props.dismissHandler()}
                 src={closeIcon}
            ></img>
          </div>

          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Swiper
              spaceBetween={0}
              slidesPerView={1}
              speed={0}
              modules={[Navigation, Pagination, Keyboard]}
              navigation
              pagination={{ type: 'fraction' }}
              initialSlide={props.startingInd}
              onSwiper={swiper => setSwiperInstance(swiper)}
              onRealIndexChange={e => handleSlideChange(e)}
              keyboard={{
                enabled: true,
                onlyInViewport: true,
              }}
              style={{
                width: '100%',
                flexGrow: 1,
              }}
              lazy
            >
              {props.mediaData &&
                props.mediaData.length > 0 &&
                props.mediaData.map((media: any) => {
                  const currentUrl = imageUrl || media.url;
                  return (
                    <SwiperSlide key={media.id} className='media-slider-swiper-container'>
                      <div className='check-report-marking-img-container media-slider-content-container'>
                        {media.type?.toLowerCase() === 'photo' ? (
                          <div className='media-slider-outer-container'>
                            <div className='media-slider-static-content-container'>
                              <a href={media.url} target="_blank" download="image.png">
                                <object
                                  className='media-slider-img'
                                  data={currentUrl}
                                  type='image/png'
                                >
                                </object>
                              </a>
                            </div>
                            <div className='media-slider-static-content-container'>
                              <img className='media-slider-rotate-left-icon' onClick={() => {rotateImage(media.url, 90).then()}}
                                src={rotateLeftIcon}></img>
                              <img src={downloadIcon} onClick= {async event => await downloadImage(media.url)}
                                   className='cross-icon cursor_pointer media-slider-cross-icon'></img>
                              <img className='media-slider-rotate-right-icon' onClick={() => {rotateImage(media.url, -90).then()}}
                                src={rotateRightIcon}></img>
                            </div>
                          </div>
                        ) : media.type?.toLowerCase() === 'video' ? (
                          <div className='media-slider-outer-container'>
                            <div className='media-slider-static-content-container'>
                              <video
                                id='media-slider-video'
                                controls
                                src={media.url}
                                className='media-slider-img media-slider-video'
                              />
                            </div>
                            <div className='media-slider-static-content-container'>
                              <a href={media.url} download="video.mp4" target="_blank">
                                <img src={downloadIcon} className='cross-icon cursor_pointer media-slider-cross-icon'></img>
                              </a>
                            </div>
                          </div>
                        ) : props.mediaType === 'markings' ? <img src={media.marked_image ? media.marked_image : media.image} className='media-slider-img' /> : null }
                      </div>
                    </SwiperSlide>
                  )
                })}
            </Swiper>
          </div>
        </div>
        {props.mediaType === 'markings' && (
          <Flex justify='center' align='center' wrap='wrap' css={{ gap: 16, py: 16 }}>
            {props.mediaData2?.map((marking: any) => (
              <div key={marking.idx || marking.id} className='display_flex legend-item-container'>
                <div className='marking-legend-color-container' style={{ backgroundColor: `#${marking.color}` }}></div>
                <p className='marking-legend-text'>{JSON.parse(marking.text_i18n)[i18n.language] || marking.default_text}</p>
              </div>
            ))}
          </Flex>
        )}
      </Modal>
      )
    </div>
  )
}

export default MediaSlider
